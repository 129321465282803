<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{on,attrs}">
      <v-btn
        icon
        dark
        v-bind="attrs"
        v-on="on"
      >   
        <v-icon
          small
          class="mr-2"
          color="grey"
        >
          mdi-cog
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Usuário: {{currentItem.text}}
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <h1>Editar usuario</h1>
        <v-text-field label="Nome"/>
        <v-text-field label="CPF"/>
        <v-select
          v-model="select"
         
          :items="items"
          item-text="state"
          item-value="abbr"
          label="Selecionar"
          persistent-hint
          return-object
          single-line
        ></v-select>
       
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name:"Edit",
  props:{
    currentItem:{
      type:Object
    }
  },data:()=>({
    dialog:false,
    select: '',
        items: [
          { id:1,state: 'Colaborador'  },
          { id:2,state: 'Avaliador'  },
          
        
        ],
  }),
  
}
</script>